<template>
  <div @mousewheel="goWheel($event)">
    <div class="homeCode">
      <div class="homeCode-child Mini">
        <span class="hover-line"></span>
        <img :src="Mini" alt="">
        <span>小程序</span>
        <div class="Mini-code">
          <img :src="MiniCode" alt="">
        </div>
      </div>
      <div class="homeCode-child Android">
        <span class="hover-line"></span>
        <img :src="Android" alt="">
        <span>安卓</span>
        <div class="Android-code">
          <img :src="androidDownLoadCode" alt="">
        </div>
      </div>
      <div class="homeCode-child Apple">
        <span class="hover-line"></span>
        <img :src="Apple" alt="">
        <span>IOS</span>
        <div class="Apple-code">
          <img :src="androidDownLoadCode" alt="">
        </div>
      </div>
    </div>
    <el-carousel indicator-position="none" :style="{height:swiperHeight}" @change="change" ref="swiper" @mousewheel="goWheel($event)" class="home" direction="vertical" :autoplay="false" :loop="false">
      <el-carousel-item class="homeTop " v-lazy:background-image="topImg">
        <!-- <img v-lazy="topImg" alt="" /> -->
        <!-- <div class="homeTop-text"> -->
        <!-- <img v-lazy="topText" alt="" /> -->
        <!-- </div> -->
      </el-carousel-item>
      <el-carousel-item class="homeCenter" v-lazy:background-image="bg1">
        <div class="homeCenter-left">
          <img v-lazy="logo" alt="" />
          <p>产品理念</p>
          <p>帮助宠主便携照顾爱宠提供</p>
          <p>科学养宠方案，见证爱宠的</p>
          <p>成长轨迹</p>
        </div>
        <div class="homeCenter-center">
          <img v-lazy="centerImg" alt="" />
          <!-- <div class="homeCenter-right">
            <div class="homeCenter-right-child Mini">
              <span class="hover-line"></span>
              <img :src="Mini" alt="">
              <span>小程序</span>
              <div class="Mini-code">
                <img :src="code" alt="">
              </div>
            </div>
            <div class="homeCenter-right-child Android">
              <span class="hover-line"></span>
              <img :src="Android" alt="">
              <span>安卓</span>
              <div class="Android-code">
                <img :src="code" alt="">
              </div>
            </div>
            <div class="homeCenter-right-child Apple">
              <span class="hover-line"></span>
              <img :src="Apple" alt="">
              <span>IOS</span>
              <div class="Apple-code">
                <img :src="code" alt="">
              </div>
            </div>
          </div> -->
        </div>
      </el-carousel-item>
      <el-carousel-item class="homeWhy" v-lazy:background-image="whyBg">
        <div class="homeWhy-left">
          <img v-lazy="whyImg" alt="">
        </div>
        <div class="homeWhy-right">
          <div class="homeWhy-right-title1">
            为什么<div class="line">述宠<span></span></div>是
          </div>
          <div class="homeWhy-right-title2">
            养宠人士的<div class="line">不二选择<span></span></div>
          </div>
          <p>述宠已科学养宠为宗旨，尽全力为</p>
          <p>养宠人士提供最便捷的科学养宠途</p>
          <p>径，让宠物鱼宠主相互陪伴的旅途</p>
          <p>更加舒心美好</p>
        </div>
      </el-carousel-item>
      <el-carousel-item class="homeTeam">
        <div class="homeTeam-top">
          <p>专业服务团队</p>
          <span>为产品提供专业且系统的系列服务</span>
        </div>
        <div class="homeTeam-center">
          <div class="homeTeam-center-left">
            {{ teamSurplusList[0].title }}
          </div>
          <div class="homeTeam-center-center">
            <img src="http://testshop.ichongapp.com/home/left.png" alt="" @click="teamTitleChange('-')">
            <div>
              <p>{{ teamList[teamIndex].title }}</p>
              <p>{{ teamList[teamIndex].span }}</p>
            </div>
            <img src="http://testshop.ichongapp.com/home/right.png" alt="" @click="teamTitleChange('+')">
          </div>
          <div class="homeTeam-center-right">
            {{ teamSurplusList[1].title}}
          </div>
        </div>
        <el-carousel ref="itemSwiper" @change="itemChange" class="homeTeam-bottom" :interval="5000" arrow="never" indicator-position="none">
          <el-carousel-item v-for="(item,index) in teamContentList" :key="index">
            <div class="homeTeam-bottom-center" :style="{'background-image':'url('+item.bg+')'}">
              <img :src="item.img" alt="" srcset="">
              <div>
                <p>{{ item.title }}</p>
                <p>{{ item.content }}</p>
              </div>
            </div>

          </el-carousel-item>
        </el-carousel>
      </el-carousel-item>
      <el-carousel-item class="homeMore">
        <img class="homeMore-bg" v-lazy="moreBg" alt="" />
        <div class="homeMore-left">
          <div class="homeMore-left-top">
            <p>快为您的爱宠</p>
            <p>定制专属成长手册</p>
          </div>
          <router-link :to="{name:'Introduce'}">
            <div class="homeMore-left-center" @click="navChange('产品介绍')">
              <p>了解更多</p>
              <img :src="$imgUrl+'home/next.png'" alt="" srcset="">
            </div>
          </router-link>
        </div>
        <div class="homeMore-right">
          <img v-lazy="moreImg" alt="" />
        </div>

      </el-carousel-item>
    </el-carousel>

  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  props: ['navTitle'],

  data() {
    return {
      swiperHeight: document.body.clientHeight - 52 + 'PX',
      swiperWidth: document.body.clientWidth + 'PX',
      swiperIndex: 0,
      one: true,//以此来控制每次轮播图切换的张数
      topImg: this.$imgUrl + 'home/top.png',
      // topText: require('http://testshop.ichongapp.com/home/topText.png'),
      logo: this.$imgUrl + 'home/logo.png',
      Mini: require('../../assets/home/index/Mini.png'),
      Android: require('../../assets/home/index/Android.png'),
      Apple: require('../../assets/home/index/Apple.png'),
      MiniCode: require('../../assets/home/MiniCode.png'),
      code: require('../../assets/home/code.png'),
      androidDownLoadCode: require('../../assets/home/index/androidDownLoadCode.png'),
      whyImg: require('../../assets/home/whyImg.png'),
      whyBg: require('../../assets/home/whyBackground.png'),
      centerImg: this.$imgUrl + 'home/centerImg.png',
      moreBg: this.$imgUrl + 'home/moreBg.png',
      moreImg: this.$imgUrl + 'home/moreImg.png',
      bg1: this.$imgUrl + 'home/bg1.png',

      navList: [
        {
          title: '首页',
          name: 'Home'
        },
        {
          title: '产品介绍',
          name: 'Introduce'
        },
        {
          title: '关于我们',
          name: 'About'
        },
        {
          title: '产品下载',
          name: 'Download'
        },
      ],
      teamIndex: 1,
      teamTitle: '',
      title: '',
      downloadList: [
        { img: this.$imgUrl + 'home/wx.png', title: '小程序' },
        { img: this.$imgUrl + 'home/android.png', title: '安卓' },
        { img: this.$imgUrl + 'home/apple.png', title: 'ios' },
      ],
      teamList: [
        { title: '专业团队', span: '齐聚各大资深养宠团队指导运维' },
        { title: '科学养宠', span: '多学科宠物专家输出养宠知识' },
        { title: '一对一客服', span: '专业客服人员提供一对一服务' },
      ],
      teamContentList: [
        {
          img: this.$imgUrl + 'home/team.png',
          title: '述宠·专业团队',
          content: '一流运维团队持续开发客户需求，致力于为每一位用户提供最舒心的使用体验',
          bg: this.$imgUrl + 'home/teamBg3.png'
        },
        {
          img: this.$imgUrl + 'home/science.png',
          title: '述宠·科学养宠',
          content: '为更多宠主输送全方面的科学养宠知识，尽可能让每一只宠物都能健康成长',
          bg: this.$imgUrl + 'home/teamBg1.png'

        },
        {
          img: this.$imgUrl + 'home/service.png',
          title: '述宠·一对一客服',
          content: '专业客服团队提供一对一在线解答，尽力针对宠主的问题提供最专业的解答',
          bg: this.$imgUrl + 'home/teamBg2.png'

        },
      ],
      teamSurplusList: [{ title: '专业团队', span: '多学科宠物专家输出养宠知识' },
      { title: '一对一客服', span: '多学科宠物专家输出养宠知识' }],

    }
  },

  watch: {
    navTitle: {
      immediate: true,
      handler(newVal) {
        this.$emit('navTitleChange', newVal)
      },

    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.swiperHeight = (window.fullHeight - 52) + 'PX'
      })()

    }
  },
  methods: {

    PX2rem(PX) {
      if (/%/ig.test(PX)) { // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
        return PX
      } else {
        return (parseFloat(PX) / 16) + 'rem' // 这里的37.5，和rootValue值对应
      }
    },
    change(e) {
      this.swiperIndex = e
    },
    goWheel(event) {
      if (event.deltaY > 0 && this.one == true) {
        this.$refs.swiper.next();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        if (this.swiperIndex == 3) {
          this.swiperIndex = 4
        }
        if (this.swiperIndex == 4) {
          this.$emit("goEnd", true)
        } else {
          this.$emit("goEnd", false)
        }
      }

      if (event.deltaY < 0 && this.one == true) {
        this.$refs.swiper.prev();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        this.$emit("goEnd", false)
      }
    },
    downloadClick(title) {
      this.$emit('downloadClick', title)
    },
    navChange(title) {
      this.navTitle = title
    },
    itemChange(e) {
      this.teamIndex = e
    },
    teamTitleChange(symbol) {
      if (symbol == '+') {
        if (this.teamIndex < 2) {
          this.teamIndex++
        } else {
          this.teamIndex = 0
        }
      }
      if (symbol == '-') {
        if (this.teamIndex > 0) {
          this.teamIndex--

        } else {
          this.teamIndex = 2
        }
      }
      this.$refs.itemSwiper.setActiveItem(this.teamIndex)
      this.teamSurplusList = []
      if (this.teamIndex == 0) {
        this.teamSurplusList.push(this.teamList[2])
        this.teamSurplusList.push(this.teamList[1])
      }
      if (this.teamIndex == 1) {
        this.teamSurplusList.push(this.teamList[0])
        this.teamSurplusList.push(this.teamList[2])
      }
      if (this.teamIndex == 2) {
        this.teamSurplusList.push(this.teamList[1])
        this.teamSurplusList.push(this.teamList[0])
      }

    },

  }
}
</script>
<style lang="scss" scoped >
.homeCode {
  z-index: 999;
  position: absolute;
  right: 30px;
  top: 102px;
  width: 38px;
  height: 225px;
  border-radius: 27px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;
  // box-shadow: 0 0 15PX #a8ced4;
  box-shadow: 0 0 15px #a8ced4;

  .homeCode-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 21px;
      height: 21px;
    }

    span {
      color: #7bc8d2;
      font-size: 7px;
      font-family: SourceHanSansCN-Medium;
    }
  }

  .homeCode-child:hover {
    cursor: pointer;
  }

  .Mini {
    margin-top: 43px;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Mini-code {
      display: none;
      position: absolute;
      top: 20px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Mini:hover .Mini-code,
  .Mini:hover .hover-line {
    display: block;
  }
  .Android {
    margin: 30px 0;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Android-code {
      display: none;
      position: absolute;
      top: 80px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Android:hover .Android-code,
  .Android:hover .hover-line {
    display: block;
  }
  .Apple {
    margin-bottom: 43px;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Apple-code {
      display: none;
      position: absolute;
      top: 150px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Apple:hover .Apple-code,
  .Apple:hover .hover-line {
    display: block;
  }
}

img[lazy="loading"] {
  width: 400px !important;
  height: 50px !important;
}
::-webkit-scrollbar {
  display: none;
}
/deep/ .el-carousel__container {
  height: 100% !important;
}

.home {
  // min-width: 1680PX !important;
  position: relative;
  // height: 889PX !important;

  // .homeDownload-list {
  //   display: flex;
  //   align-items: center;
  //   height: 100%;
  //   justify-content: flex-end;
  //   margin-right: 60PX;
  //   position: fixed;
  //   right: 0PX;
  //   .homeDownload-list-right {
  //     box-shadow: 0PX 2PX 16PX 3PX rgba($color: #7bc8d2, $alpha: 0.18);

  //     background-color: #fff;
  //     width: 90PX;
  //     height: 472PX;
  //     border-radius: 45PX;
  //     margin-left: 30PX;
  //     margin-top: -120PX;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: flex-end;
  //     div {
  //       margin-top: 57PX;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       justify-content: center;
  //       padding-right: 14PX;
  //       img {
  //         width: 54PX;
  //         height: 54PX;
  //       }
  //       p {
  //         font-size: 16PX;
  //         color: #7bc8d2;
  //         margin-top: 9PX;
  //         font-weight: bold;
  //       }
  //       .homeDownload-list-right-code {
  //         width: 170PX;
  //         height: 170PX;
  //         border-radius: 20PX;
  //         background-color: #fff;
  //         margin-bottom: 28PX;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         position: absolute;
  //         left: -173PX;
  //         img {
  //           width: 143PX;
  //           height: 143PX;
  //           margin-left: 10PX;
  //         }
  //       }
  //     }
  //   }
  // }
  .homeTop {
    // width: 100%;
    height: 100%;
    // min-width: 1680PX;
    // background-image: url('http://testshop.ichongapp.com/home/top.png');
    background-size: cover;
    background-position: center;
    // transform: translate(-50%) !important;
    // position: relative;
    // left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      // width: 100%;
      height: 100%;
      position: fixed;
    }
    // .homeTop-text{
    //   position: absolute;
    //   left: 50%;
    //   top: 38.41%;
    //   transform: translateX(-50%);
    //   width: 1366PX;
    //   text-align: left;
    //   img {
    //   width: 292PX;
    //   height: 198PX;
    // }
    // }
  }
  .homeCenter {
    //width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url('http://testshop.ichongapp.com/home/bg1.png');
    background-size: cover;
    .homeCenter-left {
      display: flex;
      flex-direction: column;
      // margin-right: 183PX;
      img {
        width: 80PX;
        height: 63PX;
        letter-spacing: 0PX;
      }
      p:nth-child(2) {
        color: #333;
        font-size: 40PX;
        font-family: SourceHanSansCN-Bold;
        margin-top: 31PX;
        margin-bottom: 24PX;
        letter-spacing: 0PX;
      }
      p {
        color: #333;
        font-size: 20PX;
        line-height: 34PX;
        letter-spacing: 6PX;
        font-family: SourceHanSansCN-Medium;
      }
    }
    .homeCenter-center {
      display: flex;
      margin-left: 150PX;
      img {
        width: 415PX;
        height: 336PX;
      }
    }
  }

  .homeWhy {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .homeWhy-left {
      width: 399PX;
      height: 403PX;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .homeWhy-right {
      margin-left: 138PX;
      .homeWhy-right-title1 {
        display: flex;
        font-size: 41PX;
        font-family: SourceHanSansCN-Bold;
        color: #333;

        .line {
          font-size: 41PX;
          font-family: SourceHanSansCN-Bold;
          color: #333;
          z-index: 2;
          position: relative;

          span {
            width: 83PX;
            height: 15PX;
            background-color: #92d5de;
            z-index: -1;
            position: absolute;
            bottom: 5PX;
            left: 0;
          }
        }
      }

      .homeWhy-right-title2 {
        font-size: 41PX;
        font-family: SourceHanSansCN-Bold;
        color: #333;
        margin-bottom: 35PX;
        display: flex;

        .line {
          font-size: 41PX;
          font-family: SourceHanSansCN-Bold;
          color: #333;
          z-index: 2;
          position: relative;

          span {
            width: 165PX;
            height: 15PX;
            background-color: #92d5de;
            z-index: -1;
            position: absolute;
            bottom: 5PX;
            left: 0;
          }
        }
      }

      p {
        font-size: 20PX;
        color: #999999;
        font-family: SourceHanSansCN-Medium;
        margin-bottom: 7PX;
        letter-spacing: 7PX;
      }
    }
  }

  .homeTeam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-bottom: 105PX;
    height: 100%;
    //width: 100%;
    .homeTeam-top {
      width: 100%;
      text-align: center;
      // margin-top: 20PX;
      p {
        font-size: 32PX;
        // font-weight: bold;
        font-family: "SourceHanSansCN-Medium";
        color: #000;
        margin-bottom: 13PX;
      }
      span {
        font-size: 14PX;
        // font-weight: bold;
        color: #999999;
      }
    }
    .homeTeam-center {
      display: flex;
      align-items: center;
      margin-top: 51PX;
      align-items: center;
      justify-content: center;
      .homeTeam-center-left,
      .homeTeam-center-right {
        // font-weight: bold;
        // position: absolute;

        width: 138PX;
        font-size: 28PX;
        color: #e1e1e1;
      }
      .homeTeam-center-left {
        margin-right: 65PX;
      }
      .homeTeam-center-right {
        margin-left: 65PX;
      }
      .homeTeam-center-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        width: 363PX;
        p:nth-child(1) {
          font-size: 28PX;
          color: #7bc8d2;
          font-family: "SourceHanSansCN-Medium";
          margin-bottom: 15PX;
        }
        p:nth-child(2) {
          font-size: 16PX;
          color: #8c8c8c;
          // font-weight: 300;
        }
      }
      img {
        width: 36PX;
        height: 36PX;
        // position: absolute;
      }
      img:first-child {
        // margin-right: 153PX;
      }
      img:last-child {
        // margin-left: 153PX;
      }
      img:hover {
        cursor: pointer;
      }
    }
    @media screen and (max-height: 758PX) {
      .homeTeam-bottom {
        margin-top: 32PX !important;
      }
      .homeTeam-bottom-center {
        height: 300PX !important;
        img {
          height: 270PX !important;
        }
      }
    }
    .homeTeam-bottom {
      margin-top: 52PX;
      // flex: 1;
      width: 100%;
      height: 350PX;

      /deep/ .el-carousel__container {
        display: flex;
        justify-content: center;
      }
      .homeTeam-bottom-center {
        margin: 0 auto;
        // background-image: url("http://testshop.ichongapp.com/home/teamBg1.png");
        background-size: 100% 100%;
        width: 944PX;
        height: 350PX;
        display: flex;
        align-items: center;
        // margin-bottom: 130PX;
        img {
          width: 250PX;
          height: 320PX;
          margin-left: 116PX;
          margin-right: 63PX;
        }
        div {
          display: flex;
          flex-direction: column;
          margin-right: 147PX;
          p:nth-child(1) {
            font-size: 22PX;
            color: #333333;
            font-family: "SourceHanSansCN-Medium";
            margin-bottom: 29PX;
            letter-spacing: 4PX !important;
          }
          p {
            font-size: 16PX;
            color: #666666;
            line-height: 26PX;
            letter-spacing: 2PX;
          }
          p:last-child {
            white-space: break-spaces;
          }
        }
      }
    }

    .homeTeam-bottom:hover {
      cursor: pointer;
    }
  }
  .homeMore {
    // background-image: url("http://testshop.ichongapp.com/home/moreBg.png");
    // background-size: 100% 100%;
    //width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: -200PX;
    position: relative;
    .homeMore-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: -100;
    }
    .homeMore-left {
      .homeMore-left-top {
        margin-bottom: 21PX;
        // position: absolute;
        // left: 0;
        // right: 0;
        // top: 354PX;
        p {
          font-size: 34PX;
          color: #000;
          font-weight: bold;
          line-height: 60PX;
          letter-spacing: 6PX;
        }
      }
      .homeMore-left-center {
        // position: absolute;
        // left: 0;
        // right: 0;
        // top: 590PX;
        display: flex;
        border: 1PX solid #7bc8d2;
        border-radius: 18PX;
        width: 120PX;
        height: 36PX;
        align-items: center;
        justify-content: center;
        img {
          width: 12PX;
          height: 12PX;
          margin-left: 6PX;
        }
        p {
          font-size: 16PX;
          color: #7bc8d2;
          font-weight: 500;
          font-family: "SourceHanSansCN-Medium";
        }
      }
    }
    .homeMore-right {
      margin-left: 133PX;
      img {
        width: 365PX;
        height: 323PX;
      }
    }
  }
}
</style>
